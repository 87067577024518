import http from '../shared_client_utils/http';

export default {
  fetchWaiver: (code, clientId) => {
    return http.get(`${window.baseApiPath}/waivers`, { params: { code, clientId } });
  },
  saveWaiver: (data) => {
    return http.post(`${window.baseApiPath}/waivers`, { data });
  },
};
